import React from 'react' ;
import './header.css' ;
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';

const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>Lets Build Something amazing with GPT-3 OPEN AI</h1>
        <p>GPT-3, the third iteration of OpenAI's Generative Pre-trained Transformer model, stands as a pinnacle in natural language processing, boasting unparalleled capabilities in understanding and generating human-like text across diverse domains, </p>
        <div className='gpt3__header-content__input'>
          <input type='email' placeholder='Your Email Address'/>
          <button type='button'>Get Started</button>
          </div>

        <div className='gpt3__header-content__people'>
          <img src={people} alt='people'/>
          <p>1,600 requested access a visit in the last 24 hours.</p>
        </div>
      </div>
        <div className='gpt3__header-image'>
          <img src={ai} alt='ai'/>
        </div>
    </div>
  )
}

export default Header